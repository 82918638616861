import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import BagianJuknis from "./components/bagian-juknis"
import logoBiru from './assets/img/fans-kreatif.svg'

import './App.scss'

const RECAPTCHA_SITE_KEY = "6Ldz2UgbAAAAABsiiQcMaf4X6Q9DIu9Rju9e41MZ"
const BACKEND_SERVER_URL = "https://kreatif.fans.co.id/submit-karya.php"

enum FormStatus { idle, submitting, success, error }

interface KaryaProps {
  nama?: string,
  email?: string,
  judul?: string,
  kontak?: string,
  agreement?: boolean,
  berkas?: File
}

interface KaryaState extends KaryaProps {
  formStatus: FormStatus
  serverStatus?: any
}

class Home extends React.Component<KaryaProps, KaryaState> {

  berkasKarya: React.RefObject<any>
  recaptchaRef: React.RefObject<any>

  constructor(props: KaryaProps) {
    super(props)
    this.state = {
      nama: '',
      email: '',
      judul: '',
      kontak: '',
      berkas: undefined,
      agreement: false,
      formStatus: FormStatus.idle
    }
    this.berkasKarya = React.createRef()
    this.recaptchaRef = React.createRef()
    this.handleButtonPilihBerkas = this.handleButtonPilihBerkas.bind(this)
    this.onBerkasKaryaSelected = this.onBerkasKaryaSelected.bind(this)
    this.handleTextInputChanged = this.handleTextInputChanged.bind(this)
    this.onFormSubmitted = this.onFormSubmitted.bind(this)
  }

  onBerkasKaryaSelected(e: any) {
    if (e.target.files?.length > 0) {
      var newState: any = this.state
      newState.berkas = e.target.files[0]
      this.setState(newState)
    }
  }

  resetState() {
    let newState :KaryaState = {
      nama: '',
      email: '',
      judul: '',
      kontak: '',
      berkas: undefined,
      agreement: false,
      formStatus: FormStatus.idle
    }
    this.setState(newState)
  }

  async onFormSubmitted(e: any) {
    e.preventDefault()
    let newState :KaryaState = this.state
    newState.formStatus = FormStatus.submitting
    this.setState(newState)
    const data = new FormData()

    data.append('nama', this.state.nama!)
    data.append('email', this.state.email!)
    data.append('kontak', this.state.kontak!)
    data.append('judul', this.state.judul!)
    data.append('lampiran', this.state.berkas!)
    data.append('g-recaptcha-response', this.recaptchaRef.current.getValue())

    try {
      const response = await fetch(BACKEND_SERVER_URL, {
        method: 'POST',
        body: data
      })
      const jsonRespons = await response.json()
      if (response.status >= 400){
        // ada yang gagal
        let newState: KaryaState = this.state
        newState.formStatus = FormStatus.error
        newState.serverStatus = jsonRespons
        this.setState(newState)
        return
      } else {
        // sukses
        let newState: KaryaState = this.state
        newState.formStatus = FormStatus.success
        this.setState(newState)
        return
      }
    }
    catch (e) {
      alert(e)
      this.resetState()
    }
  }

  handleTextInputChanged(e: any) {
    var newState: any = this.state
    newState[e.target.name] = e.target.value
    this.setState(newState)
  }

  handleButtonPilihBerkas() {
    this.berkasKarya.current.click()
  }

  buildOnFormProgress() {
    return (
      <div className="form-submit-progress">
        <div className="indicator"></div>
        <div className="text">Sedang mengirim karya</div>
      </div>
    )
  }

  buildOnFormAfterSuccess() {
    setTimeout(() => {
      this.resetState()
    }, 1500);
      return (
        <div className="form-submit-after">
          <div className="alert alert-success">Karya telah dikirimkan</div>
          <button className="btn btn-primary" >Kirim karya lain</button>
        </div>
      )
  }
  buildOnFormAfterFailed() {
    setTimeout(() => {
      let newState :KaryaState = this.state
      newState.formStatus = FormStatus.idle
      this.setState(newState)
    }, 1500);
    return (
      <div className="form-submit-after">
        <div className="alert alert-danger">Gagal Mengirimkan Karya</div>
        <button className="btn btn-primary" >Kirim karya lain</button>
      </div>
    )
  }

  buildBagianForm = () => {
    return (
      <form id="form-karya-kreatif" method="POST" onSubmit={this.onFormSubmitted} encType="multipart/form-data">
        <div className="row mb-3">
          <div className="col">
            {/* <h4><strong>Kirim Karyamu Sekarang</strong></h4> */}
          </div>
        </div>
        <div className="row mb-3 mb-lg-0">
          <div className="col-lg-6 mb-3">
            <label className="form-label" htmlFor="nama">
              <strong>Nama Kamu</strong>
            </label>
            <input className="form-control" value={this.state.nama} onChange={this.handleTextInputChanged} type="text" placeholder="Mis. Miitan Itsurugi" name="nama" required autoComplete="off" autoFocus />
          </div>
          <div className="col-lg-6">
            <label className="form-label" htmlFor="email">
              <strong>Alamat Email</strong>
            </label>
            <input className="form-control" value={this.state.email} onChange={this.handleTextInputChanged} type="text" placeholder="Mis. miitan@gmail.com" name="email" autoComplete="off" inputMode="email" required />
          </div>
        </div>
        <div className="row mb-3 mb-lg-0">
          <div className="col-lg-6 mb-3">
            <label className="form-label" htmlFor="kontak">
              <strong>Kontak</strong>
            </label>
            <input className="form-control" value={this.state.kontak} onChange={this.handleTextInputChanged} type="tel" placeholder="Mis. 0812345678912" name="kontak" required autoComplete="off" autoFocus inputMode="tel" />
          </div>
          <div className="col-lg-6">
            <label className="form-label" htmlFor="judul">
              <strong>Judul Karya</strong>
            </label>
            <input className="form-control" value={this.state.judul} onChange={this.handleTextInputChanged} type="text" placeholder="Mis. Purnama Biru" name="judul" autoComplete="off" required />
          </div>
        </div>
        <div className="row p-2 p-lg-4 mb-4" id="unggah-container">
          <div className="col">
            <input className="form-control" onChange={this.onBerkasKaryaSelected} ref={this.berkasKarya} type="file" id="fileinput" required name="berkas_karya" />
            <div className="input-group flex-lg-row flex-column mb-3">
              <input value={this.state.berkas ? this.state.berkas.name : ''} required readOnly onClick={this.handleButtonPilihBerkas} name='berkas_karya_placeholder' className="form-control label-unggah-berkas" type="text" placeholder="Unggah ZIP File" />
              <button className="btn btn-primary" onClick={this.handleButtonPilihBerkas} type="button">Pilih Berkas</button>
            </div>
            <label htmlFor="berkas_karya" className="form-label text-center">Sertakan berkas SVG,PNG dalam ukuran 1080px persegi serta berkas TXT berisi deskripsi lengkap tentang karya dala satu berkas ZIP.&nbsp;</label>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-check">
              {this.state.agreement
                ?
                <input className="form-check-input" checked required type="checkbox" name="agreement" />
               :
                <input className="form-check-input" required type="checkbox" name="agreement" />
               }
              <label className="form-check-label" id="agreement-label" htmlFor="agreement">Saya telah membaca &amp; menyetujui dengan seluruh <a href="https://drive.google.com/file/d/1Ff7ITbJGo9Ebd69mYveCgRcbSJ_mL_tI/view?usp=sharing" >peraturan kontes</a></label>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <ReCAPTCHA
            ref={this.recaptchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center text-lg-end">
            <button className="btn btn-primary" id="btn-submit" type="submit">Kirim Karya</button>
          </div>
        </div>
      </form>
    )
  }

  buildTheForm() {
    var widget :JSX.Element
    switch (this.state.formStatus) {
      case FormStatus.submitting:
        widget = this.buildOnFormProgress()
        break
      case FormStatus.error:
        widget = this.buildOnFormAfterFailed()
        break
      case FormStatus.success:
        widget = this.buildOnFormAfterSuccess()
        break
      default:
        widget = this.buildBagianForm()
        break
    }
    return widget
  }

  render() {
    return (
      <>
        <div className="backgrounds">
          <div className="row">
            <div className="col-lg-6" id="bg1"></div>
            <div className="col-lg-6" id="bg2"></div>
          </div>
        </div>
        <main>
          <div className="container">
            <div className="row py-4">
              <div className="col-lg-7 col-xl-7">
                <div className="card p-3 px-md-5">
                  <div className="card-body">
                    <img src={logoBiru} alt="logo fans" className="img-fluid my-3" />
                    {this.buildTheForm()}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-xl-5" id="konten-juknis">
                <BagianJuknis />
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

export default Home
