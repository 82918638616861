import logoWhite from '../assets/img/img-logo-white.svg'

export default function BagianJuknis(){
    return (
        <div className="card p-5">
            <div className="card-body">
            <div className="row mb-3">
                <div className="col-sm-10 col-md-8 col-lg-6">
                <img className="img-fluid" src={logoWhite} alt="fans-logo" />
                </div>
            </div>
            <p>Kontes Template Brosur Sekolah merupakan kompetisi desain brosur lipat tiga (trifold) yang diselenggarakan oleh Sepatu Fans dan berkolaborasi dengan komunitas Gimpscape ID. Kontes ini terbuka dan dapat diikuti oleh seluruh warga negara Indonesia. Perlu dicatat bahwa, untuk mengikuti kontes ini, para calon peserta tidak dipungut biaya sepeserpun. </p>
            <p>Untuk dapat mengikuti Kontes Template Brosur Sekolah, para peserta  diwajibkan untuk mengikuti beberapa ketentuan kontes sesuai petunjuk teknsi yang telah disediakan.</p>
            <a className="btn btn-primary" id="btn-unduh-juknis" href="https://s.id/fans-kreatif">Unduh Juknis</a>
            </div>
        </div>
    );
}
